$tablet-width: 768px;
$desktop-width: 1024px;
$mobileL-width: 425px;
$mobileS-width: 320px;

@mixin mobile {
  @content;
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.image-manager {
    display: flex;
    flex-direction: column;
    gap: 1em;

    .images {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 10px;

        > div {
            img {
                width: 100%;
            }

            .controls {
                display: flex;
                justify-content: center;
            }
        }
    }
}
.products {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    width: fit-content;

    .product {
        box-sizing: border-box;
        width: 100%;
        border: 1px solid black;
        padding: 8px;
        min-width: 200px;
        display: grid;
        grid-template-columns: auto auto;

        .name {
            display: flex;
            height: 100%;
            align-items: center;
            min-width: 200px;
        }
        .actions {
            display: flex;
            .action {
                &:not(:last-of-type) {
                    margin-right: 10px;
                }

                padding: 5px;
                background-color: #ff9166;
                color: white;
                border-radius: 5px;
                text-decoration: none;
            }
        }
    }
}

.customisations {
    .customisation {
        .customisation-root {
            margin-bottom: 10px;
        }

        padding: 5px;
        border: 1px solid black;
    }
}

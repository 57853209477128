@import './mixins';

.grid {
    display: grid;
}

@for $i from 1 through 10 {
    .grid-#{$i} {
        grid-template-columns: repeat($i, 1fr);
    }

    .grid-tablet-#{$i} {
        @include tablet {
            grid-template-columns: repeat($i, 1fr);
        }
    }

    .grid-desktop-#{$i} {
        @include desktop {
            grid-template-columns: repeat($i, 1fr);
        }
    }

    .grid-gap-#{$i} {
        gap: #{$i}rem;
    }
}
